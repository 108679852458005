/*
    Global Vuestic Config
    Example of usage:
    In Vuestic UI elements, color="zbr-primary"
    In stylesheet, color: var(--va-zbr-primary)
*/

import { createIconsConfig } from "vuestic-ui";

const vuesticConfig = {
    config: {
        colors: {
            'primary': '#007AAB',
            'zbr-primary': '#007AAB',
            'zbr-primary-fade': '#E8F2F8',
            'zbr-primary-fade-2': '#76B1D9',
            'zbr-primary-fade-3': '#B5D8ED',
            'zbr-gray-1': '#1F2937',
            'zbr-gray-2': '#111827',
            'zbr-gray-3': '#9CA3AF',
            'zbr-gray-4': '#4B5563',
            'zbr-gray-5': '#6B7280',
            'zbr-gray-6': '#E5E7EB',
            'zbr-gray-7': '#F3F4F6',
            'zbr-black-900': '#0F0F0F',
            'zbr-black-700': '#2C2F33',
            'zbr-black-700-hover': 'rgba(44, 47, 51, 0.76)',
            'zbr-black-700-opacity': 'rgba(44, 47, 51, 0.8)',
            'zbr-gray-900': '#707880',
            'zbr-gray-700': '#888F94',
            'zbr-gray-400': '#ADB2B8',
            'zbr-gray-300':'#D5DBE0',
            'zbr-gray-200': '#E1E6EB',
            'zbr-primary-20': 'rgba(118, 177, 217, 0.2)',
            'zbr-primary-30': 'rgba(118, 177, 217, 0.3)',
            'zbr-success': '#00C13B',
            'zbr-error': '#E23100'
        },
        icons: createIconsConfig({
            fonts: [
                {
                    name: '{icon}-fill',
                    resolve: (value => ({
                        content: value.icon,
                        class: 'material-symbols-outlined material-symbols-filled'
                    }))
                }
            ]
        })
    }
};

export default vuesticConfig;