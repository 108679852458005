import { defineStore } from 'pinia';

export const useLoadingDialogStore = defineStore('loadingDialog', {
    state: () => ({
        active: false,
    }),
    actions: {
        showDialog(status: boolean) {
            this.active = status;
        },
    },
});