/**
 * Now has [de, fr, en]
 * @returns [
  {
    iso: 'de',
    label: 'Deutsch',
    abbr: 'DE',
    locale: 'de',
  },
    {
    iso: 'fr',
    label: 'French',
    abbr: 'FR',
    locale: 'fr',
  },
  {
    iso: 'en',
    label: 'English',
    abbr: 'EN',
    locale: 'en',
  },
]
 */
export interface LANGUAGE_ITEM {
    iso: string,
    label: string,
    abbr: string,
    locale: string,
}

export const LANGUAGES: LANGUAGE_ITEM[] = [
    {
        iso: 'de',
        label: 'Deutsch',
        abbr: 'DE',
        locale: 'de',
    },
    {
        iso: 'fr',
        label: 'French',
        abbr: 'FR',
        locale: 'fr',
    },
    {
        iso: 'en',
        label: 'English',
        abbr: 'EN',
        locale: 'en',
    },
];

/**
 * @returns de
 */
export const SUPPORTED_LANGUAGES = LANGUAGES.map((l) => l.iso);
/**
 * @returns de
 */
export const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES[0];
/**
 * @returns DE
 */
export const DEFAULT_LANGUAGE_ABBR = LANGUAGES.find((lang) => lang.iso === DEFAULT_LANGUAGE)?.abbr;
/**
 * @returns de
 */
export const FALLBACK_LANGUAGE = SUPPORTED_LANGUAGES[0];