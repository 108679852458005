import { defineStore } from 'pinia';

interface ShowDialog {
    status: boolean,
    message: string,
    title?: string
}

export const useSimpleDialogStore = defineStore('simpleDialog', {
    state: () => ({
        showSimpleDialog: false,
        simpleDialogMessage: '',
        simpleDialogTitle: ''
    }),
    actions: {
        showDialog(payload: ShowDialog) {
            this.showSimpleDialog = payload.status;
            this.simpleDialogMessage = payload.message;
            if (payload.title) {
                this.simpleDialogTitle = payload.title;
            }
        },
    },
});